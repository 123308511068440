import { useEffect, useState } from 'react';
import supabase from '../config/supabaseConfig';
import { useAtom } from 'jotai';
import {
    authLoaderAtom,
    authSessionAtom,
    authUserAtom,
    authUserRolesAtom,
} from '../utils/store';
import { navigate } from 'gatsby';

const AuthProvider = ({ children }) => {
    const [loading, setLoading] = useAtom(authLoaderAtom);
    const [session, setSession] = useAtom(authSessionAtom);
    const [, setUser] = useAtom(authUserAtom);
    const [, setRoles] = useAtom(authUserRolesAtom);
    const [timer, setTimer] = useState(1800 * 1000);

    useEffect(() => {
        const updateState = (session) => {
            setSession(session ?? null);
            setUser(session?.user ?? null);
            setRoles(session?.user?.user_metadata?.roles ?? []);
            setTimer(session?.expires_in * 500 ?? 1800 * 1000);
            setLoading(false);
        };

        const handleAuthStateChange = async (event, session) => {
            setLoading(true);

            if (
                !loading &&
                !session &&
                !window.location.pathname.includes('/auth')
            ) {
                const currentPath =
                    `/sign-in?backTo=${encodeURIComponent(
                        window.location.pathname
                    )}` || '/sign-in';
                navigate(currentPath);
            }

            if (event === 'INITIAL_SESSION') {
                updateState(session || null);
            } else if (event === 'SIGNED_IN') {
                updateState(session);
            } else if (event === 'SIGNED_OUT') {
                updateState(null);
            } else if (event === 'TOKEN_REFRESHED') {
                updateState(session);
            }
        };

        const { data: authListener } = supabase.auth.onAuthStateChange(
            handleAuthStateChange
        );

        return () => {
            authListener?.subscription.unsubscribe();
        };
    }, [setSession, setUser, setRoles, setLoading, loading]);

    useEffect(() => {
        const refreshInterval = setInterval(async () => {
            if (session) {
                const { error } = await supabase.auth.refreshSession();
                if (error) console.error(error);
            }
        }, timer); // Adjust as necessary

        return () => clearInterval(refreshInterval);
    }, [session, timer]);
    return children;
};

export default AuthProvider;
