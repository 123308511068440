import React from 'react';
import './src/styles/App.css';
import '@fontsource/ibm-plex-sans';
import QueryProvider from './src/context/QueryProvider';
import CustomThemeProvider from './src/context/ThemeProvider';
import AuthProvider from './src/context/AuthProvider';
import NotificationHandler from './src/components/Notifications/NotificationHandler';

export const wrapPageElement = ({ element }) => {
    return (
        <>
            {element}
            <NotificationHandler />
        </>
    );
};

export const wrapRootElement = ({ element }) => {
    return (
        <QueryProvider>
            <CustomThemeProvider>
                <AuthProvider>{element}</AuthProvider>
            </CustomThemeProvider>
        </QueryProvider>
    );
};
