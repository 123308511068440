import React, { useEffect, useState } from 'react';
import { Alert, IconButton, LinearProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ToastNotification = ({
    id,
    message,
    statusCode,
    timeout = 5000,
    onClose,
}) => {
    const [progress, setProgress] = useState(100);
    const [severity, setSeverity] = useState('info');

    useEffect(() => {
        if (statusCode >= 200 && statusCode < 300) {
            setSeverity('success');
        } else if (statusCode >= 400 && statusCode < 500) {
            setSeverity('error');
        } else {
            setSeverity('info');
        }
    }, [statusCode]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => {
                const newProgress = prevProgress - 100 / (timeout / 100);
                return newProgress < -15 ? 0 : newProgress;
            });
        }, timeout / 100);

        if (progress < -10) {
            clearInterval(timer);
            onClose(id);
        }

        return () => {
            clearInterval(timer);
        };
    }, [id, timeout, progress, onClose]);

    const getProgressBarColor = () => {
        switch (severity) {
            case 'success':
                return '#2e7d32';
            case 'error':
                return '#d32f2f';
            default:
                return '#0288d1';
        }
    };

    return (
        <Alert
            severity={severity}
            sx={{
                position: 'relative',
                overflow: 'hidden',
                pointerEvents: 'auto',
                width: 'fit-content',
            }}
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        onClose(id);
                    }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            }
        >
            {message}
            <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: getProgressBarColor(),
                    },
                }}
            />
        </Alert>
    );
};

export default ToastNotification;
