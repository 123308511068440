import { atom } from 'jotai';

//Auth
export const authLoaderAtom = atom(true);
export const authSessionAtom = atom(null);
export const authUserAtom = atom(null);
export const authUserRolesAtom = atom([]);

// Notifications
export const notificationsAtom = atom([]);

// Feed State
export const calendarStepAtom = atom(0);
export const filterStatusAtom = atom('all');

//Articles
export const articleAtom = atom(null);
export const articlesAtom = atom([]);
export const isVerifiedArticleAtom = atom(false);
export const isHiddenArticleAtom = atom(false);
export const urlRedirectAtom = atom('/');
export const editArticleIdAtom = atom(null);

//Topics
export const topicsAtom = atom([]);

//Authors
export const authorsAtom = atom([]);

// Modal
export const isModalOpenAtom = atom(false);
