import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.GATSBY_SUPABASE_URL;
const supabaseKey = process.env.GATSBY_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseKey, {
    auth: {
        autoRefreshToken: true, // Set this to true to automatically refresh the access token
        persistSession: true, // Set this to true to persist the session data in localStorage
    },
});

// Define the session() method on the auth object
supabase.auth.session = () => {
    const session = localStorage.getItem('supabase.auth.token');
    return session ? JSON.parse(session) : null;
};

export default supabase;
