import React from 'react';
import { Stack } from '@mui/material';
import ToastNotification from './ToastNotification';
import useNotification from '../../hooks/useNotification';

const NotificationHandler = () => {
    const { notifications, removeNotification } = useNotification();

    const handleNotificationClose = (id) => {
        removeNotification(id);
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                position: 'fixed',
                bottom: '10px',
                right: '10px',
                width: '50%',
                height: '90vh',
                zIndex: 9999,
                pointerEvents: 'none',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
            }}
        >
            {notifications.map((notification) => (
                <ToastNotification
                    key={notification.id}
                    id={notification.id}
                    message={notification.message}
                    statusCode={notification.statusCode}
                    timeout={notification.timeout}
                    onClose={handleNotificationClose}
                />
            ))}
        </Stack>
    );
};

export default NotificationHandler;
