import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { grey, teal } from '@mui/material/colors';
import React, { useMemo } from 'react';

const CustomThemeProvider = ({ children }) => {
    const theme = useMemo(
        () =>
            createTheme({
                typography: {
                    fontFamily: 'IBM Plex Sans',
                    title: {
                        fontFamily: 'IBM Plex Sans',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '1.25rem',
                        lineHeight: '1.625rem',
                    },
                    content: {
                        fontFamily: 'IBM Plex Sans',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '1rem',
                        lineHeight: '1rem',
                    },
                    boldContent: {
                        fontFamily: 'IBM Plex Sans',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '1rem',
                        lineHeight: '1rem',
                    },
                    cardTitle: {
                        fontFamily: 'IBM Plex Sans',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        fontSize: '1rem',
                    },
                    cardContent: {
                        fontFamily: 'IBM Plex Sans',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        fontSize: '0.85rem',
                    },
                },
                palette: {
                    primary: {
                        main: teal[500],
                        grey: grey[500],
                        white: '#ffffff',
                        black: '#000000de',
                        modalBg: '#00000060',
                    },
                },
                components: {
                    MuiAppBar: {
                        styleOverrides: {
                            root: ({ theme }) => ({
                                // use JavaScript conditional expression
                                background:
                                    theme.palette.mode === 'dark'
                                        ? '#121212'
                                        : '#fff',
                            }),
                        },
                    },
                },
            }),
        []
    );
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default CustomThemeProvider;
