import { useAtom } from 'jotai';
import { notificationsAtom } from '../utils/store';

const useNotification = () => {
    const [notifications, setNotifications] = useAtom(notificationsAtom);

    const addNotification = (message, statusCode, timeout = 5000) => {
        const notification = {
            id: Date.now(),
            message,
            statusCode,
            timeout,
        };
        setNotifications((prevNotifications) => [
            ...prevNotifications,
            notification,
        ]);
    };

    const removeNotification = (id) => {
        setNotifications((prevNotifications) =>
            prevNotifications.filter((n) => n.id !== id)
        );
    };

    return { notifications, addNotification, removeNotification };
};

export default useNotification;
